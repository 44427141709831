import React from "react"
import { Routes } from "../../../routes"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Organization } from "../../../interfaces/apiResponse.interface"

const OrganizationUI = (user: any, organizations: any) => {
  const history = useHistory()
  const { t } = useTranslation("global")
  const currentOrganization = JSON.parse(
    localStorage.getItem("CURRENT_ORGANIZATION") || "{}"
  )

  return (
    <div className="text-light text-center w-100">
      <span className="d-flex gap-2 w-100 align-items-center justify-content-center">
        <i className="fa fa-building text-center"></i>
        <span>{currentOrganization?.Organization?.Name}</span>
      </span>
      <hr className="" />
      <div
        className="text-truncate rounded bg-secondary text-dark  change-org-btn"
        onClick={() => {
          history.push(Routes.SelectOrganization.path)
        }}
      >
        <div className="d-flex align-items-center text-truncate p-2 px-1 justify-content-center">
          <span className="text-truncate">{t("change_organization")}</span>
        </div>
        {/* <small className="text-muted">{t("change_organization")}</small> */}
      </div>
    </div>
  )
}

export default OrganizationUI
