import * as Yup from "yup"

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
})

export const completeTaskSchema = Yup.object().shape({
  // startTime: Yup.string()
  //   .required("Required")
  //   .test(
  //     "startTime-before-endTime",
  //     "Start time must be before end time",
  //     function (value) {
  //       const { endTime } = this.parent
  //       return !endTime || !value || value < endTime
  //     }
  //   ),
  // endTime: Yup.string(),
  amountSpent: Yup.number(),
  runnerNote: Yup.string(),
  file: Yup.mixed(),
})

const vehicleType = [
  "SUV",
  "Sedan",
  "Van",
  "Sprinter Van",
  "Shuttle",
  "Bus",
  "Truck",
  "Mini Van",
  "Cargo Van",
  "Others",
]
export const createRunnerSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string(),
  email: Yup.string().email("Invalid Email"),
  contactNumber: Yup.string().required("Required"),
  vehicleTypes: Yup.array().of(Yup.string().oneOf(vehicleType)),
  startTime: Yup.string().required("Required"),
  // .test(
  //   "startTime-before-endTime",
  //   "Start time must be before end time",
  //   function (value) {
  //     const { cutTime } = this.parent
  //     return !cutTime || !value || value < cutTime
  //   }
  // ),
  cutTime: Yup.string().required("Required"),
  cap: Yup.number(),
  floatAmount: Yup.number(),
})

export const newRunnerSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string(),
  email: Yup.string().email("Invalid Email"),
  contactNumber: Yup.string().required("Required"),
  vehicleTypes: Yup.array().of(Yup.string().oneOf(vehicleType)),
})


export const editRunnerSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string(),
  email: Yup.string().email("Invalid Email"),
  contactNumber: Yup.string().required("Required"),

})
