import CRUDField from "../core/genericCRUD/CRUDField"

export const vehicleTypes = [
  {
    id: "SUV",
    name: "SUV",
  },

  {
    id: "Sedan",
    name: "Sedan",
  },
  {
    id: "Van",
    name: "Van",
  },
  {
    id: "Sprinter Van",
    name: "Sprinter Van",
  },
  {
    id: "Shuttle",
    name: "Shuttle",
  },
  {
    id: "Bus",
    name: "Bus",
  },
  {
    id: "Truck",
    name: "Truck",
  },
  {
    id: "Mini Van",
    name: "Mini Van",
  },
  {
    id: "Cargo Van",
    name: "Cargo Van",
  },
  {
    id: "Others",
    name: "Others",
  },
]

const NewRunnerDTO: CRUDField[] = [
  {
    name: "runnerInformationSection",
    title: "Runner Information",
    htmlType: "separator",
    dbType: "string",
    required: false,
    containerClass: "col-12",
  },
  {
    name: "id",
    title: "id",
    htmlType: "hidden",
    dbType: "string",
    required: false,

    containerClass: "col-md-6 col-12",
  },
  {
    name: "firstName",
    title: "First Name",
    htmlType: "text",
    dbType: "string",
    required: true,
    containerClass: "col-md-6 col-12",
  },

  {
    name: "lastName",
    title: "Last Name",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "vehicleType",
    title: "Vehicle Type",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSourceLocal: vehicleTypes,
  },
  {
    name: "contactNumber",
    title: "Contact Number",
    htmlType: "text",
    dbType: "string",
    required: true,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "email",
    title: "Email",
    htmlType: "email",
    dbType: "string",
    required: false,
    containerClass: "col-md-12 col-12",
  },
]

export const EditRunnerDTO: CRUDField[] = [
  {
    name: "runnerInformationSection",
    title: "Runner Information",
    htmlType: "separator",
    dbType: "string",
    required: false,
    containerClass: "col-12",
  },
  {
    name: "id",
    title: "id",
    htmlType: "hidden",
    dbType: "string",
    required: false,

    containerClass: "col-md-6 col-12",
  },
  {
    name: "firstName",
    title: "First Name",
    htmlType: "text",
    dbType: "string",
    required: true,
    containerClass: "col-md-6 col-12",
  },

  {
    name: "lastName",
    title: "Last Name",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
  },

  {
    name: "contactNumber",
    title: "Contact Number",
    htmlType: "text",
    dbType: "string",
    required: true,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "email",
    title: "Email",
    htmlType: "email",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
  },
]


export default NewRunnerDTO
