import { Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Empty from "../Empty"
import { RunnerItem } from "./RunnerItem"
import { useEffect, useState } from "react"
import API from "../../common/API"
import ActionBar from "../core/ActionBar"
import Filter from "../core/Filter/Filter"
import { FilterObject } from "../../interfaces/filterObj.interface"
import HomeFilterFields from "../Home/HomeFilter.fields"
import AddNewRunnerModal from "./AddNewRunnerModal"
import Swal from "sweetalert2"
import EditRunnerModal from "./EditRunnerModal"

const Runners = () => {
  const [runners, setRunners] = useState([])
  const [t] = useTranslation("global")
  const [isLoading, setIsLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const toggleFilter = () => setShowFilter(!showFilter)
  const [filterObj, setFilterObj] = useState<FilterObject>({} as FilterObject)
  const [showAddEdit, setShowAddEdit] = useState(false)
  const [showEditRunner, setShowEditRunner] = useState(false)
  const [currentRunner, setCurrentRunner] = useState({})
  const filterFields = HomeFilterFields

  const getFilterValues = obj => {
    setFilterObj(obj)
  }

  const organization = JSON.parse(
    localStorage.getItem("CURRENT_ORGANIZATION") || "{}"
  )

  const fetchRunners = async () => {
    setIsLoading(true)
    try {
      const response = await API.getAction(
        "runner/list?OrganizationId=" + organization.OrganizationGUID
      )
      if (response.data.status.toString() === "ok") {
        setRunners(response.data.response)
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    fetchRunners()
  }, [])

  const toggleAddEdit = () => {
    if (Object.keys(organization).length === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please select an event and try again",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }
    setShowAddEdit(!showAddEdit)
  }

  const toggleEditRunner = () => {
    setShowEditRunner(!showEditRunner)
  }

  return (
    <>
      {/* {section === "MostSoldProduct" && (
        <MostSoldProduct toggle={toggle} show={true} />
      )} */}

      <Container
        className="m-0 p-0 w-100"
        style={{
          maxWidth: "100%",
          height: "calc(100vh - 60px)",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {showAddEdit && (
          <AddNewRunnerModal
            toggle={toggleAddEdit}
            title={t("Add Runner")}
            cssClass="modalRight"
            refreshData={fetchRunners}
          />
        )}
        {showEditRunner && (
          <EditRunnerModal
            toggle={toggleEditRunner}
            currentRunner={currentRunner}
            title={t("Edit Runner")}
            cssClass="modalRight"
            refreshData={fetchRunners}
          />
        )}
        <div className="row w-100">
          <div
            className={`${"col-md-12"} col-12 position-relative transition-all`}
          >
            <ActionBar
              title={t("Runners")}
              icon="fa fa-running"
              actions={
                <>
                  <button
                    className={
                      "btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                    }
                    onClick={() => toggleFilter()}
                  >
                    <i
                      className={`fa fa-filter ${
                        showFilter ? "text-white" : ""
                      }`}
                    />
                  </button>
                  <button
                    className={"ms-2 btn  btn-sm btn-primary  "}
                    onClick={() => {
                      toggleAddEdit()
                    }}
                  >
                    <i className="fa fa-plus" />
                  </button>
                </>
              }
            ></ActionBar>
            {showFilter && (
              <div className="my-4">
                <Filter
                  toggleFilter={toggleFilter}
                  fields={filterFields}
                  filterAction={() => {
                    fetchRunners()
                  }}
                  setFilterFields={getFilterValues}
                />
              </div>
            )}
            {isLoading ? (
              <>
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            ) : runners.length > 0 ? (
              <Row>
                {runners.map((runner, index) => (
                  <RunnerItem
                    key={index}
                    runner={runner}
                    toggleEditRunner={toggleEditRunner}
                    setCurrentRunner={setCurrentRunner}
                  />
                ))}
              </Row>
            ) : (
              <div className="h-100 col-12 justify-content-center d-flex flex-column align-items-center">
                <i className="fa fa-running fa-5x text-muted"></i>
                <div className="d-flex flex-column justify-content-between">
                  <span className="text-muted">
                    {t("No Runners Added Yet")}
                  </span>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      toggleAddEdit()
                    }}
                  >
                    {t("Add Runner")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  )
}

export default Runners
