import Swal from "sweetalert2"
import API from "../../common/API"
import { Runner } from "../../interfaces/runs.interface"
import { set } from "lodash"
import { ConsoleNetworkOutline } from "mdi-material-ui"

interface RunnerItemProps {
  runner: Runner
  toggleEditRunner: () => void
  setCurrentRunner: (runner: Runner) => void
}

export const RunnerItem = ({
  runner,
  toggleEditRunner,
  setCurrentRunner,
}: RunnerItemProps) => {
  const handleDelete = async (runnerId: string) => {
    Swal.fire({
      title: `Are you sure want to delete ${runner.firstName} ${runner.lastName} from your runners list?`,
      text: "You won't be able to revert this!",
      imageUrl: "/icons/100x100-icon.png",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const response = await API.getAction(`/runners/delete?id=${runnerId}`)
          if (response) {
            Swal.fire("Deleted!", "The runner has been deleted.", "success")
          }
        } catch (error) {
          Swal.fire(
            "Error!",
            "An error occurred while deleting the runner",
            "error"
          )
        }
      }
    })
  }

  const handleEdit = () => {
    setCurrentRunner(runner)
    toggleEditRunner()
  }

  return (
    <div className="col-12 col-md-6 col-lg-4 ">
      <div className="card p-1 runner-card">
        <div className="runner-card-btn-container">
          <button
            className="btn btn-danger btn-sm"
            onClick={() => {
              handleDelete(runner.id)
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              handleEdit()
            }}
          >
            <i className="fas fa-edit"></i>
          </button>
        </div>
        <div className="d-flex align-items-center">
          <img
            src="https://via.placeholder.com/150"
            className="card-img-top rounded w-25"
            alt="runner_avatar"
            width={100}
            height={100}
          />
          <div className="card-body w-75">
            <h5 className="card-title">
              {runner.firstName} {runner.lastName}
            </h5>
            <p className="card-text">
              {runner.email}
              <br />
              {runner.mobilePhone}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
